<template>
    <div class="rq-doc-management rq-container content-wrapper">
        <rq-page-section title="Document Management" headerSize="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item" v-rq-tooltip.hover.top="localSecurity.FileScanCanAttachFilesAndDocuments ? '' : 'Access Restricted'">
                        <b-button
                            :automation_id="elementName('btn_add')"
                            :ref="elementName('btn_add')"
                            class="btn btn-sm btn-theme me-1"
                            variant="theme"
                            @click="onAddDocuments"
                            @dragover="onAddDocumentDragOver"
                            v-rq-tooltip.hover.top="{ title: `Add Document(s)` }"
                            :disabled="!localSecurity.FileScanCanAttachFilesAndDocuments || fileIsLocked"
                            >Add Document
                        </b-button>
                        <b-button
                            :automation_id="elementName('btn_refresh')"
                            :ref="elementName('btn_refresh')"
                            class="btn btn-sm btn-theme me-1"
                            variant="theme"
                            @click="onRefreshDocuments"
                            v-rq-tooltip.hover.top="{ title: `Refresh` }"
                            >Refresh
                        </b-button>
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <div class="row">
            <div class="col col-3">
                <rq-action-form-group
                    label="Search"
                    show-action>
                    <template #action>
                        <b-form-checkbox
                            automation_id="chk_search_all_docs"
                            id="chk_search_all_docs"
                            v-model="searchAll">Search All Documents</b-form-checkbox>
                    </template>
                    <rq-search-input-group
                        id="grid_search"
                        v-model="searchText"
                        :placeholder="`Search Documents...`"
                        tabindex="0"
                        show-search-button
                        @keyup="onDocumentSearchEnter"
                        @search="onDocumentSearch"
                        @clear="onDocumentSearchClear"
                    />
                </rq-action-form-group>
            </div>
            <div class="col col-3 form-group">
                <label for="tb_tag_filter">Tag Filter</label>
                <dx-tag-box
                    automation_id="tb_tag_filter"
                    ref="tb_tag_filter"
                    class="form-control"
                    :data-source="documentTags"
                    display-expr="name"
                    value-expr="id"
                    :show-selection-controls="true"
                    :show-clear-button="true"
                    :max-displayed-tags="3"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    @valueChanged="onTagFilterChange"
                    v-model="filterTagIDs"
                />
            </div>
            <div v-show="showGroupByCategory"
                class="col col-auto mx-auto form-group pt-4">
                <b-form-checkbox
                    id="cb_group_by_category"
                    automation_id="cb_group_by_category"
                    v-model="groupByCategory"
                    @change="onChangeGridGroupBy"
                    >Group By Category
                </b-form-checkbox>
            </div>
            <div class="col col-4 form-group">
                <label for="chk_grid_preview">Document Preview</label><br/>
                <div class="form-control border-0">
                    <rq-switch type="stop-light"
                        size="md"
                        automation_id="chk_grid_preview"
                        v-model="showPreview"
                        @change="onShowPreviewChange"
                    />
                </div>
            </div>
        </div>
        <div class="doc-management-main">
            <div class="row">
                <div :class="docsClassAttr">
                    <RqTabs
                        :tabs="tabItems"
                        class="doc-mgmt h-100"
                        v-model="tabIndex">
                        <template #file-documents>
                            <rqdx-action-data-grid
                                ref="dataGrid"
                                automation_id="tbl_file_scan_documents_list"
                                :actions="selectionActions"
                                :config="gridConfig"
                                :data-source="gridDataSource"
                                export-file-name="file_scan_documents"
                                v-model:validationErrors="validationErrors"
                                focus-after-last-row="first-row"
                                @delete="onDeleteItem"
                                @set-tags="onSetTags($event, 'set')"
                                @add-tag="onSetTags($event, 'add')"
                                @remove-tag="onSetTags($event, 'remove')"
                                @copy="onCopyMoveItem($event, 'Copy')"
                                @move="onCopyMoveItem($event, 'Move')"
                                @merge="onMergeItems"
                                @edit-props="onEditProperties"
                                @edit-doc="onEditDocument"
                                @set-category="onSetCategory"
                                @print="onPrintItems"
                                @save="onSaveItem"
                                @save-as="onSaveItemAs"
                                @email="onEmailItems"
                                @reset="onReset"
                                @selectionChanged="onGridSelectionChanged"
                                rq-filters
                                rq-editable
                                hide-search>
                                <template #toolbar>
                                    <div class="d-inline-block me-auto" v-if="tabIndex == 0 && groupByCategory">
                                        <button title="Expand All"
                                            type="button"
                                            class="btn btn-link btn-theme p-0"
                                            :disabled="allGroupsExpanded"
                                            @click="onExpandAllGroups(true)">
                                            Expand All
                                        </button>
                                        <span class="link-divider"> | </span>
                                        <button title="Collapse All"
                                            type="button"
                                            class="btn btn-link btn-theme p-0"
                                            :disabled="allGroupsCollapsed"
                                            @click="onExpandAllGroups(false)">
                                            Collapse All
                                        </button>
                                    </div>
                                </template>
                            </rqdx-action-data-grid>
                        </template>
                        <template #primary-file-documents>
                            <rqdx-action-data-grid
                                ref="primaryDataGrid"
                                automation_id="tbl_primary_file_scan_documents"
                                :config="primaryGridConfig"
                                :data-source="primaryGridDataSource"
                                export-file-name="primary_file_scan_documents"
                                @selectionChanged="onGridSelectionChanged"
                                rq-filters
                                hide-search>
                                <template #toolbar>
                                    <div class="d-inline-block me-auto" v-if="tabIndex == 1 && groupByCategory">
                                        <button title="Expand All"
                                            type="button"
                                            class="btn btn-link btn-theme p-0"
                                            :disabled="allPrimaryGroupsExpanded"
                                            @click="onExpandAllPrimaryGroups(true)">
                                            Expand All
                                        </button>
                                        <span class="link-divider"> | </span>
                                        <button title="Collapse All"
                                            type="button"
                                            class="btn btn-link btn-theme p-0"
                                            :disabled="allPrimaryGroupsCollapsed"
                                            @click="onExpandAllPrimaryGroups(false)">
                                            Collapse All
                                        </button>
                                    </div>
                                </template>
                            </rqdx-action-data-grid>
                        </template>
                        <template #system-documents>
                            <rqdx-action-data-grid
                                ref="searchDataGrid"
                                automation_id="tbl_file_scan_documents_search"
                                :actions="searchSelectionActions"
                                :config="searchGridConfig"
                                :data-source="searchGridDataSource"
                                export-file-name="file_scan_documents_search_results"
                                @copy="onCopyMoveItem($event, 'Copy')"
                                @copy-current="onCopyMoveItemToCurrent($event, 'Copy')"
                                @move="onCopyMoveItem($event, 'Move')"
                                @move-current="onCopyMoveItemToCurrent($event, 'Move')"
                                @navigate="onGotoFile"
                                @selectionChanged="onGridSelectionChanged"
                                rq-filters
                                hide-search
                            />
                        </template>
                    </RqTabs>
                </div>
                <div :class="paneClassAttr">
                    <file-scan-document-pane
                        ref="docPane"
                        automation_id="file_scan_document_pane"
                        :items="selectedItems"
                        v-model:action="action"
                        v-model:save-as-file-type="saveAsFileType"
                        v-model:show-preview="showPreview"
                        @update-item="onUpdateDocument"
                        @reload-data="onReloadData"
                        @pdf-loaded="onPdfDocumentLoaded"
                        @pdf-printed="onPdfDocumentPrinted"
                    ></file-scan-document-pane>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { UPDATE_SESSION_USER } from '../../../store/actions';
    import { SYSTEM_MUTATIONS } from "@/store/mutations";
    import { FileScanDocumentDto, FileScanDocumentCopyMoveRequestDto, FileScanDocumentCopyMoveResultDto, FileScanDocumentCategoryResultDto, FileScanDocumentMergeRequestDto, FileScanDocumentAssignTagRequestDto, FileScanDocumentAssignTagResultDto }  from "../models";
    import { SearchRequest } from "@/shared/models/models";
    import { DocumentFileType, FileScanDocumentType }  from "@documents/enums";
    import { FileScanDocumentDuplicateAction, FileScanDocumentAction }  from "../enums";
    import { SystemLookupItem } from "@/shared/models/models";
    import DxTagBox from "devextreme-vue/tag-box";
    import SortableList from "@/shared/components/rq/SortableList";
    import DocumentsSaveAsDialog  from "../components/DocumentsSaveAsDialog.vue";
    import FileScanDocumentAssignTag  from "../components/FileScanDocumentAssignTag.vue";
    import FileScanDocumentProperties  from "../components/FileScanDocumentProperties.vue";
    import FileScanDocumentsAdd  from "../components/FileScanDocumentsAdd.vue";
    import FileNumberDialog from "@/shared/components/rq/dialogs/FileNumberDialog.vue";
    import FileScanDocumentPane  from "../components/FileScanDocumentPane.vue";
    import EmailModal from "@/shared/components/rq/dialogs/EmailForm";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

    export default {
        name:"FileScanDocumentsList",
        components: { DxTagBox, FileScanDocumentPane },
        data () {
            return {
                action: 0,
                addEventName: "",
                allGroupsCollapsed: false,
                allGroupsExpanded: true,
                allPrimaryGroupsCollapsed: false,
                allPrimaryGroupsExpanded: true,
                canPrint: false,
                filterTagIDs: [],
                items: [],
                primaryItems: [],
                saveAsFileType: 0,
                searchAll: false,
                searchItems: [],
                searchText: "",
                selectedItems: [],
                selectionActions: [],
                showPreview: true,
                tabIndex: 0,
                validationErrors: [],
            };
        },
        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initListeners();
            this.fetchData();
            this.fetchPrimaryData();
            this.initDefaults();
        },
        beforeUnmount () {
            window.removeEventListener("resize", this.onWindowResize);
        },
        watch: {
            fileIsLocked:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue) && !_.isNil(newValue)) {
                        this.refresh();
                    }
                }
            },
            searchAll:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue) && !newValue) {
                        if (!newValue && this.filterTagIDs.length > 0) {
                            this.filterTagIDs = [];
                        }
                        this.refresh();
                    }
                }
            },
            orderId:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        if (!this.isSecondaryFile && this.tabIndex > 0) {
                            this.tabIndex = 0;
                        }
                        this.fetchData();
                        this.fetchPrimaryData();
                    }
                }
            },
            showPreview:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        this.$store.commit(SYSTEM_MUTATIONS.SET_SHOW_DOC_MGMT_PREVIEW, newValue);
                    }
                }
            },
            tabIndex(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$nextTick().then(()=>{
                    if(newValue === 0) {
                        this.gridInstance.updateDimensions();
                    } else if(newValue === 1) {
                        let primaryGrid = this.getPrimaryGrid();
                        if (_.isEmpty(primaryGrid)) return;
                        primaryGrid.updateDimensions();
                    } else if(newValue === 2) {
                        let searchGrid = this.getSearchGrid();
                        if (_.isEmpty(searchGrid)) return;
                        searchGrid.updateDimensions();
                    }
                });
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                orderId: state => state.orders.orderId,
                order: state => state.orders.order,
                branchId: state => state.orders.orderSummary.branchID,
                fileIsLocked: state => state.orders.orderSummary.isLocked,
                showDocMgmtPreview: state => state.system.showDocMgmtPreview,
                user: state => state.authentication.session.user
            }),
            documentTags(){
                const self = this;
                let selectedDocumentTagIds = _.uniq(_.flatten(_.map(self.items, x => x.tagIDs)));
                return _.filter(self.lookupHelpers.getAllLookupItems(self.lookupItems.DOCUMENT_TAGS), x => !x.inactive || _.includes(selectedDocumentTagIds, x.id));
            },
            localSecurity(){
                return this.securitySettings.findValues([
                    "CanCopyDocuments",
                    "CanCopyDocumentsToLocked",
                    "CanDeleteDocuments",
                    "CanEditDocuments",
                    "CanEmailDocuments",
                    "CanPrintDocuments",
                    "CanOpenDocuments",
                    "CanMergeDocuments",
                    "FileScanCanAttachFilesAndDocuments",
                    "PredefinedDescriptions",
                    "FileScanAllowEditMode",
                    "FileScanAllowEditDocuments",
                    "FileScanCanDeleteDocuments"
                    ]);
            },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            primaryGridInstance() { return _.get(this, "$refs.primaryDataGrid.gridInstance", null) || {}; },
            docsClassAttr() { return this.showPreview ? "col col-6 py-2 h-100" : "col col-12 py-2 h-100"; },
            isSecondaryFile() { return _.getNumber(this, "order.primaryOrdersID", 0) > 0; },
            paneClassAttr() { return this.showPreview ? "col col-6 m-0 h-100" : "d-none"; },
            showGroupByCategory() {
                return this.tabIndex == 0 || (this.tabIndex == 1 && this.isSecondaryFile) ;
            },
            tabItems() {
                return [
                        { title: "File Documents", name: "file-documents" },
                        { title: "Primary File Documents", name: "primary-file-documents", visible: this.isSecondaryFile },
                        { title: "System Documents", name: "system-documents", visible: this.searchAll }
                    ];
            },
            groupByCategory: {
                get() { return this.$store.state.system.docMgmtGroupByCategory; },
                set(val){this.$store.commit(SYSTEM_MUTATIONS.SET_DOC_MGMT_GRP_BY_CATEGORY, val); }
            },
        },
        mounted() {
        },
        methods: {
            initDefaults() {
                const self = this;
                if (_.isBoolean(self.showDocMgmtPreview)) {
                    self.showPreview = self.showDocMgmtPreview;
                } else {
                    let branch = self.lookupHelpers.getBranch(self.user.branchID);
                    self.showPreview = _.getBool(branch, "documentPreview", true);
                }
            },
            clear() {
                if (_.isEmpty(this.gridInstance)) return;
                this.selectedItems=[];
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
                let primaryGrid = this.getPrimaryGrid();
                if (!_.isEmpty(primaryGrid)) {
                    primaryGrid.option("focusedRowIndex", -1);
                    primaryGrid.clearSelection();
                }
                let searchGrid = this.getSearchGrid();
                if (_.isEmpty(searchGrid)) return;
                searchGrid.option("focusedRowIndex", -1);
                searchGrid.clearSelection();
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.FileScanApi.getFileScanDocuments(self.orderId);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.uniqBy(_.map(result, i => new FileScanDocumentDto(i)), 'fileScanDocumentID');
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            fetchPrimaryData() {
                const self = this;
                let ordersID = _.getNumber(self, "order.primaryOrdersID", 0);
                if (ordersID == 0) return;
                let apiPromise = self.$api.FileScanApi.getFileScanDocuments(ordersID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.primaryItems = _.uniqBy(_.map(result, i => new FileScanDocumentDto(i)), 'fileScanDocumentID');
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            fetchSearchData(loadOptions) {
                const self = this;
                let request = new SearchRequest({
                    searchTerm: self.searchText,
                    pagingEnabled: true
                });
                request.parseLoadOptions(loadOptions);
                if(!request.searchTerm)
                    return Promise.resolve({data: [], totalCount: 0});

                let searchParams = { };
                searchParams.OrdersID = self.orderId;
                searchParams.TagIDList = self.filterTagIDs.length > 0 ? _.join(self.filterTagIDs, ',') : "";

                request.parameters = searchParams;
                let apiPromise = self.$api.FileScanApi.searchFileScanDocuments(request);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let items = _.map(result, i => new FileScanDocumentDto(i));
                        return { data: items, totalCount: items.length };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading System ${self.itemTypeNamePlural}.` });
                        return { data: null, totalCount: 0 };
                    })
                    .finally(() => {
                        // let searchGrid = self.getSearchGrid();
                        // if (!_.isEmpty(searchGrid)) {
                        //     searchGrid.searchByText(self.searchText);
                        // }
                    });
            },

            getExistingFileScanCategoryID(item){
                let dup = {};
                dup = _.find(this.items, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.description))
                           && _.parseNumber(i.fileType, -1) === _.parseNumber(item.fileType, -1);
                });
                return dup.fileScanCategoryID;
            },

            getGridSelectionActions() {
                const self = this;
                let disableEdit;
                let disableDocEdit;

                if(!self.fileIsLocked){
                    disableEdit = (!self.localSecurity.FileScanAllowEditMode);
                    disableDocEdit = (!self.localSecurity.FileScanAllowEditDocuments);
                } else {
                    disableEdit = (!self.localSecurity.CanEditDocuments);
                    disableDocEdit = (!self.localSecurity.CanOpenDocuments);
                }
                return [
                    { name: "edit", text: "Edit", automation_id: 'btn_edit_document',
                        children: (disableEdit && disableDocEdit) ? [] : [
                            { name: "editProps",
                              text: "Properties",
                              automation_id: 'btn_edit_properties',
                              eventName: "edit-props",
                              requireSelection: true,
                              tooltip: "Edit Properties, like description, category, etc",
                              disabled: function(e) {
                                return disableEdit;
                              }
                            },
                            { name: "editDoc",
                              text: "Document",
                              automation_id: 'btn_edit_selected_document',
                              eventName: "edit-doc",
                              requireSelection: true,
                              tooltip: "Edit Original Document",
                              disabled: function(e) {
                                  return (disableDocEdit) || !_.includes(self.editAllowed, e.data.fileType)  || e.data.isReadOnly;
                              }
                            },
                            { name: "setTag",
                              text: "Tag(s)",
                              automation_id: 'btn_set_tag_document',
                              eventName: "set-tags",
                              requireSelection: true,
                              allowMultiSelection: false,
                              tooltip: "Set Tags for a single Document",
                              disabled: function(e) {
                                return disableEdit;
                              }
                            },
                        ],
                        disabled : function(e) {
                            return (disableEdit && disableDocEdit) ? 'Access Restricted' : false;
                        }
                    },
                    { name: "manage", text: "Manage", automation_id: 'btn_manage_document',
                        children: [
                            { name: "copy", text: "Copy", eventName: "copy", automation_id: 'btn_copy_document', requireSelection: true, allowMultiSelection: true, tooltip: `Copy selected documents to another file`, disabled: function(e) { return self.fileIsLocked && !(self.localSecurity.CanCopyDocuments || self.localSecurity.CanCopyDocumentsToLocked);} },
                            { name: "delete", text: "Delete", eventName: "delete", automation_id: 'btn_delete_document', requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}`, disabled: function(e) { return self.fileIsLocked ? !self.localSecurity.CanDeleteDocuments : !self.localSecurity.FileScanCanDeleteDocuments;} },
                            { name: "merge", text: "Merge", eventName: "merge", automation_id: 'btn_merge_document', requireSelection: true, allowMultiSelection: true, tooltip: `Merge selected documents into another document`, disabled: function(e) { return (self.fileIsLocked && !self.localSecurity.CanMergeDocuments) || (_.size(e.data) == 1 || !_.every(e.data, i => i.fileType == DocumentFileType.PDF || i.fileType == DocumentFileType.PDF_COMBINED));} },
                            { name: "move", text: "Move", eventName: "move", automation_id: 'btn_move_document', requireSelection: true, allowMultiSelection: true, tooltip: `Move selected documents to another file`, disabled: function(e) { return self.fileIsLocked && !(self.localSecurity.CanCopyDocuments || self.localSecurity.CanCopyDocumentsToLocked);} },
                            { name: "save", text: "Save", eventName: "save", automation_id: 'btn_save_document', requireSelection: true, allowMultiSelection: true, disabled: function(e) { return self.fileIsLocked && !self.localSecurity.CanPrintDocuments;} },
                            { name: "saveas", text: "Save As", eventName: "save-as", automation_id: 'btn_save-as_document', requireSelection: true, allowMultiSelection: true, disabled: function(e) { return (self.fileIsLocked && !self.localSecurity.CanPrintDocuments) || !_.every(e.data, i => _.includes(self.editAllowed, i.fileType));} },
                            { name: "email", text: "Email", eventName: "email", automation_id: 'btn_email_document', requireSelection: true, allowMultiSelection: true, tooltip: `Email selected documents`, disabled: function(e) { return self.fileIsLocked && !self.localSecurity.CanEmailDocuments;} },
                        ]
                    },
                    { name: "bulk", text: "Edit Multiple", automation_id: 'btn_bulk_edit',
                        children: (disableEdit) ? [] : [
                            { name: "addTag",
                              text: "Add Tag(s)",
                              automation_id: 'btn_add_tag_document',
                              eventName: "add-tag",
                              requireSelection: true,
                              allowMultiSelection: true,
                              tooltip: "Add tag(s) to multiple Documents",
                              disabled: function(e) {
                                  return _.size(e.data) === 1 || (disableEdit);
                              }
                            },
                            { name: "removeTag",
                              text: "Remove Tag(s)",
                              automation_id: 'btn_remove_tag_document',
                              eventName: "remove-tag",
                              requireSelection: true,
                              allowMultiSelection: true,
                              tooltip: "Remove tag(s) from multiple Documents",
                              disabled: function(e) {
                                  return _.size(e.data) === 1 || (disableEdit);
                              }
                            },
                            { name: "category",
                              text: "Category",
                              automation_id: 'btn_set_category',
                              eventName: "set-category",
                              requireSelection: true,
                              allowMultiSelection: true,
                              tooltip: "Set Category on multiple Documents",
                              disabled: function(e) {
                                  return _.size(e.data) === 1 || (disableEdit);
                              }
                            },
                        ],
                        disabled : function(e) {
                            return (disableEdit) ? 'Access Restricted' : false;
                        }
                    },
                    { name: "print", text: "Print", eventName: "print", requireSelection: true, allowMultiSelection: true, tooltip: `Print selected documents`,
                        disabled: function(e) {
                            if (_.size(e.data) > 1) return "Select single document to print"; // RQO-18752
                            return (self.fileIsLocked && !self.localSecurity.CanPrintDocuments)
                                || (!self.canPrint && _.size(e.data) == 1 && _.includes(_.concat(self.editAllowed, [DocumentFileType.PDF, DocumentFileType.PDF_COMBINED]), e.data[0].fileType))
                                || e.data[0].fileType === DocumentFileType.MSG || e.data[0].fileType === DocumentFileType.EML //TODO: Remove this once print functionality is added for MSG files
                                ;
                        }
                    },
                ];
            },

            getPrimaryGrid() {
                return _.get(this.$refs, "primaryDataGrid.gridInstance", {});
            },

            getSearchGrid() {
                return _.get(this.$refs, "searchDataGrid.gridInstance", {});
            },

            isUniqueItem(item){
                let dup = {};
                dup = _.find(this.items, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.description))
                        && _.parseNumber(i.fileType, -1) === _.parseNumber(item.fileType, -1)
                        && _.parseNumber(i.fileScanDocumentID, -1) != _.parseNumber(item.fileScanDocumentID, -1)
                        ;
                });
                return _.isEmpty(dup);
            },

            initGridConfig(){
                const self = this;
                //File Docs
                self.gridConfig = {
                    onRowCollapsed: self.onRowCollapsedOrExpanded,
                    onRowExpanded: self.onRowCollapsedOrExpanded,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    onEditorPreparing: self.onEditorPreparing,
                    onEditingStart: self.onGridEditingStart,
                    summary: {
                        groupItems: [{
                            column: self.itemKey,
                            summaryType: "count",
                            displayFormat: "{0}",
                        }]
                    },
                    columns: [
                        { dataField: self.itemKey, visible: false, showInColumnChooser: false},
                        { dataField: "fileScanPageFileName", dataType: "string", caption: "File Name", cellTemplate: DxGridUtils.truncateCellTemplate, allowSearch: false, visible: false},
                        {
                            allowSearch: true,
                            dataField: "description",
                            caption: "Description",
                            dataType: "string",
                            sortIndex: 0, sortOrder: "asc",
                            cellTemplate: DxGridUtils.truncateCellTemplate,
                            setCellValue: function(rowData, value) {
                                rowData.fileScanDescriptionID = value.id;
                                rowData.description = value.name;
                                rowData.useDefaultDescription = (value.id > 0);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                let fileScanDescriptionsPlus = [];
                                let customItem = new SystemLookupItem({
                                        id: 0,
                                        name: cellInfo.data.fileScanDescriptionID > 0
                                            ? cellInfo.data.standardDescription
                                            : cellInfo.data.description
                                    });
                                if (_.parseNumber(cellInfo.data.fileScanDescriptionID, 0) > 0) {
                                    fileScanDescriptionsPlus = self.fileScanDescriptions;
                                } else {
                                    fileScanDescriptionsPlus = _.concat([customItem], self.fileScanDescriptions);
                                }
                                $("<div />").dxSelectBox({
                                    items: fileScanDescriptionsPlus,
                                    searchEnabled: true,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    acceptCustomValue: !self.localSecurity.PredefinedDescriptions,
                                    readOnly: !self.localSecurity.FileScanAllowEditMode || (self.fileIsLocked && !self.localSecurity.CanEditDocuments),
                                    value: cellInfo.data.fileScanDescriptionID,
                                    onValueChanged: function(e) {
                                        let fileScanDescription = _.find(fileScanDescriptionsPlus, ['id', e.value]);
                                        cellInfo.setValue(fileScanDescription);
                                    },
                                    onCustomItemCreating: function(e) {
                                        customItem.name = e.text;
                                        e.customItem = customItem;
                                        _.assign(fileScanDescriptionsPlus[0], customItem);
                                        cellInfo.setValue({value: customItem, cellData: cellInfo.data});
                                    }
                                }).appendTo(cellElement);
                            }
                        },
                        { allowSearch: false, dataField: "fileScanCategoryID", dataType: "number", caption: "Category", calculateSortValue: "fileScanCategoryDisplay",
                            groupIndex: self.groupByCategory ? 0 : -1,
                            groupCellTemplate: function (groupCell, info) {
                                let count = _.get(info, "summaryItems[0].value", 0);
                                let displayValue = count == 0 ? `${info.text}` : `${info.text} (${count})`;
                                $('<span>')
                                    .append(displayValue)
                                    .appendTo(groupCell);
                            },
                            editorOptions: {
                                readOnly: !self.localSecurity.FileScanAllowEditMode || (self.fileIsLocked && !self.localSecurity.CanEditDocuments),
                                dropDownOptions: {
                                    minWidth: 200
                                }
                            },
                            minWidth: 100,
                            lookup: {
                                dataSource: self.fileScanCategories,
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        { dataField: "dateCreated", dataType: "datetime", caption: "Date Created", minWidth: 135, allowSearch: false, allowSorting: true, visible: false},
                        { dataField: "dateModified", dataType: "datetime", caption: "Date Modified", minWidth: 135, allowSearch: false, allowSorting: true},
                        {
                            allowSearch: false,
                            allowFiltering: false,
                            dataField: "tagIDs",
                            dataType: "string",
                            caption: "Tags",
                            calculateSortValue: "tagNames",
                            minWidth: 80,
                            lookup: {
                                dataSource: self.documentTags,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            setCellValue: function(rowData, value) {
                                rowData.tagIDs = value;
                                let itemNames = _.map(value, id => _.find(self.documentTags, { id }).name);
                                let tagNames = _.join(itemNames, ", ");
                                rowData.tagNames = tagNames;
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", cellInfo.data.tagNames)
                                    .append(cellInfo.data.tagNames)
                                    .appendTo(cellElement);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxTagBox({
                                    dataSource: {
                                        load() {
                                            return _.filter(self.documentTags, x => !x.inactive || _.includes(cellInfo.data.tagIDs, x.id));
                                        }
                                    },
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.value,
                                    readOnly: !self.localSecurity.FileScanAllowEditMode || (self.fileIsLocked && !self.localSecurity.CanEditDocuments),
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    maxDisplayedTags: 0,
                                    width: 200,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            }
                        },
                        { allowSearch: false, dataField: "fileType", caption: "Type", calculateSortValue: "fileTypeDisplay",
                            lookup: {
                                dataSource: self.documentFileTypes,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        { allowSearch: false, dataField: "documentType", caption: "Document Type", calculateSortValue: "documentTypeDisplay", visible: false,
                            lookup: {
                                dataSource: self.fileScanDocumentTypes,
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        { dataField: "publishedByDisplay", caption: "Author", cellTemplate: DxGridUtils.truncateCellTemplate, allowSearch: false, visible: false},
                    ],
                };
                self.selectionActions = self.getGridSelectionActions();
                self.gridDataSource = {
                    loadMode: "raw",
                    key: self.itemKey,
                    load () {
                        let filteredItems = [];
                        if (!_.isEmpty(self.filterTagIDs)) {
                            _.each(self.filterTagIDs, k => {
                                filteredItems = _.unionBy(_.concat(filteredItems, _.filter(_.clone(self.items), (i) => {return _.includes(i.tagIDs, k);})), self.itemKey);
                            });

                            return Promise.resolve(filteredItems);
                        } else {
                            return Promise.resolve(self.items);
                        }
                    },
                    update: self.onGridUpdate
                };
                //Primary Docs
                self.primaryGridConfig = {
                    onRowCollapsed: self.onPrimaryRowCollapsedOrExpanded,
                    onRowExpanded: self.onPrimaryRowCollapsedOrExpanded,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    onEditorPreparing: self.onEditorPreparing,
                    onEditingStart: self.onGridEditingStart,
                    summary: {
                        groupItems: [{
                            column: self.itemKey,
                            summaryType: "count",
                            displayFormat: "{0}",
                        }]
                    },
                    columns: [
                        { dataField: self.itemKey, visible: false, showInColumnChooser: false},
                        { dataField: "fileScanPageFileName", caption: "File Name", cellTemplate: DxGridUtils.truncateCellTemplate, allowSearch: false, visible: false},
                        {
                            allowSearch: true,
                            dataField: "description",
                            caption: "Description",
                            sortIndex: 0, sortOrder: "asc",
                            cellTemplate: DxGridUtils.truncateCellTemplate,
                            setCellValue: function(rowData, value) {
                                rowData.fileScanDescriptionID = value.id;
                                rowData.description = value.name;
                                rowData.useDefaultDescription = (value.id > 0);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                let customItem = new SystemLookupItem({
                                    id: 0,
                                    name: cellInfo.data.fileScanDescriptionID > 0
                                        ? cellInfo.data.standardDescription
                                        : cellInfo.data.description
                                });
                                let fileScanDescriptionsPlus = _.concat([customItem], self.fileScanDescriptions);
                                $("<div />").dxSelectBox({
                                    items: fileScanDescriptionsPlus,
                                    searchEnabled: true,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    acceptCustomValue: !self.localSecurity.PredefinedDescriptions,
                                    readOnly: !self.localSecurity.FileScanAllowEditMode || (self.fileIsLocked && !self.localSecurity.CanEditDocuments),
                                    value: cellInfo.data.fileScanDescriptionID,
                                    onValueChanged: function(e) {
                                        let fileScanDescription = _.find(fileScanDescriptionsPlus, ['id', e.value]);
                                        cellInfo.setValue(fileScanDescription);
                                    },
                                    onCustomItemCreating: function(e) {
                                        customItem.name = e.text;
                                        e.customItem = customItem;
                                        _.assign(fileScanDescriptionsPlus[0], customItem);
                                        cellInfo.setValue({value: customItem, cellData: cellInfo.data});
                                    }
                                }).appendTo(cellElement);
                            }
                        },
                        { allowSearch: false, dataField: "fileScanCategoryID", caption: "Category", calculateSortValue: "fileScanCategoryDisplay",
                            groupIndex: self.groupByCategory ? 0 : -1,
                            groupCellTemplate: function (groupCell, info) {
                                let count = _.get(info, "summaryItems[0].value", 0);
                                let displayValue = count == 0 ? `${info.text}` : `${info.text} (${count})`;
                                $('<span>')
                                    .append(displayValue)
                                    .appendTo(groupCell);
                            },
                            editorOptions: {
                                readOnly: !self.localSecurity.FileScanAllowEditMode || (self.fileIsLocked && !self.localSecurity.CanEditDocuments),
                                dropDownOptions: {
                                    minWidth: 200
                                }
                            },
                            minWidth: 100,
                            lookup: {
                                dataSource: self.fileScanCategories,
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        { dataField: "dateModified", dataType: "datetime", caption: "Date Modified", minWidth: 135, allowSearch: false, allowSorting: true},
                        {
                            allowSearch: false,
                            allowFiltering: false,
                            dataField: "tagIDs",
                            dataType: "string",
                            caption: "Tags",
                            calculateSortValue: "tagNames",
                            minWidth: 80,
                            lookup: {
                                dataSource: self.documentTags,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            setCellValue: function(rowData, value) {
                                rowData.tagIDs = value;
                                let itemNames = _.map(value, id => _.find(self.documentTags, { id }).name);
                                let tagNames = _.join(itemNames, ", ");
                                rowData.tagNames = tagNames;
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", cellInfo.data.tagNames)
                                    .append(cellInfo.data.tagNames)
                                    .appendTo(cellElement);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxTagBox({
                                    dataSource: {
                                        load() {
                                            return self.documentTags;
                                        }
                                    },
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.value,
                                    readOnly: !self.localSecurity.FileScanAllowEditMode || (self.fileIsLocked && !self.localSecurity.CanEditDocuments),
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    maxDisplayedTags: 0,
                                    width: 200,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            }
                        },
                        { allowSearch: false, dataField: "fileType", dataType: "number", caption: "Type", calculateSortValue: "fileTypeDisplay",
                            lookup: {
                                dataSource: self.documentFileTypes,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        { allowSearch: false, dataField: "documentType", dataType: "number", caption: "Document Type", calculateSortValue: "documentTypeDisplay", visible: false,
                            lookup: {
                                dataSource: self.fileScanDocumentTypes,
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        { dataField: "publishedByDisplay", dataType: "string", caption: "Author", cellTemplate: DxGridUtils.truncateCellTemplate, allowSearch: false, visible: false},
                    ],
                };
                self.primaryGridDataSource = {
                    loadMode: "raw",
                    key: self.itemKey,
                    load () {
                        let filteredItems = [];
                        if (!_.isEmpty(self.filterTagIDs)) {
                            _.each(self.filterTagIDs, k => {
                                filteredItems = _.unionBy(_.concat(filteredItems, _.filter(_.clone(self.primaryItems), (i) => {return _.includes(i.tagIDs, k);})), self.itemKey);
                            });

                            return Promise.resolve(filteredItems);
                        } else {
                            return Promise.resolve(self.primaryItems);
                        }
                    },
                    update: self.onGridUpdate
                };
                //All Docs
                self.searchGridConfig = {
                    height: function() {
                        return window.innerHeight - 400;
                    },
                    focusedRowEnabled: true,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: true, paging: true },
                    onFocusedRowChanged: self.onSearchGridFocusedRowChanged,
                    columns: [
                        { dataField: self.itemKey, visible: false, showInColumnChooser: false, allowSearch: false},
                        { dataField: "gfNo", caption: "File#", minWidth: 100, allowSearch: true},
                        { dataField: "fileScanPageFileName", caption: "File Name", cellTemplate: DxGridUtils.truncateCellTemplate, visible: false, allowSearch: false},
                        {
                            dataField: "description",
                            allowSearch: true,
                            caption: "Description",
                            sortIndex: 0, sortOrder: "asc",
                            cellTemplate: DxGridUtils.truncateCellTemplate,
                        },
                        { dataField: "fileScanCategoryID", caption: "Category", calculateSortValue: "fileScanCategoryDisplay", allowSearch: false,
                            editorOptions: {
                                dropDownOptions: {
                                    minWidth: 200
                                }
                            },
                            minWidth: 100,
                            lookup: {
                                dataSource: self.fileScanCategories,
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        { dataField: "fileType", caption: "Type", calculateSortValue: "fileTypeDisplay", allowSearch: false,
                            lookup: {
                                dataSource: self.documentFileTypes,
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        { dataField: "documentType", caption: "Document Type", calculateSortValue: "documentTypeDisplay", allowSearch: false, visible: false,
                            lookup: {
                                dataSource: self.fileScanDocumentTypes,
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        {
                            dataField: "tagIDs",
                            dataType: "string",
                            caption: "Tags",
                            calculateSortValue: "tagNames",
                            allowSearch: false,
                            visible: false
                        },
                        { dataField: "publishedByDisplay", caption: "Author", cellTemplate: DxGridUtils.truncateCellTemplate, allowSearch: false, visible: false},
                        { dataField: "dateModified", dataType: "date", caption: "Date Modified", minWidth: 135, allowSearch: false, allowSorting: true, cellTemplate: DxGridUtils.dateTimeCellTemplate},
                    ],
                };
                self.searchGridDataSource = {
                    key: self.itemKey,
                    load: self.fetchSearchData
                };
            },

            initListeners(){
                window.addEventListener("resize", this.onWindowResize);
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Document";
                self.itemTypeNamePlural = "Documents";
                self.itemKey = "fileScanDocumentID";
                self.documentFileTypes = DocumentFileType.lookupItems;
                self.fileScanDocumentTypes = FileScanDocumentType.lookupItems;
                self.editAllowed = [DocumentFileType.DOCX, DocumentFileType.PDF];
                self.saveAsAllowed = [DocumentFileType.DOC, DocumentFileType.PDF, DocumentFileType.DOCX, DocumentFileType.HTML, DocumentFileType.RTF];
                self.readOnlyColumns = ["fileScanPageFileName", "documentType", "fileType", "publishedByDisplay", "dateModified"];
                self.fileScanDescriptions = self.lookupHelpers.getLookupItems(self.lookupItems.FILE_SCAN_DESCRIPTIONS, self.order.regionID);
                self.fileScanCategories = self.lookupHelpers.getLookupItems(self.lookupItems.FILE_SCAN_CATEGORIES, self.order.regionID);
                self.searchSelectionActions = [
                    { name: "goto", text: "Go To File", eventName: "navigate", requireSelection: true, tooltip: `Go to Document Management for this file.` },
                    { name: "copy", text: "Copy", eventName: "copy",
                        children: [
                            { name: "copy", text: `Copy to ${self.order.gfNo}`, eventName: "copy-current", requireSelection: true, allowMultiSelection: true, tooltip: `Copy selected documents to ${self.order.gfNo}` },
                            { name: "copy", text: "Copy to another file", eventName: "copy", requireSelection: true, allowMultiSelection: true, tooltip: `Copy to another file` },
                        ]
                     },
                    { name: "move", text: "Move", eventName: "move",
                        children: [
                            { name: "move", text: `Move to ${self.order.gfNo}`, eventName: "move-current", requireSelection: true, allowMultiSelection: true, tooltip: `Move selected documents to ${self.order.gfNo}` },
                            { name: "move", text: "Move to another file", eventName: "move", requireSelection: true, allowMultiSelection: true, tooltip: `Move to another file` },
                        ]
                     },
                ];

            },

            mergeFileScanDocuments(request, replaceExisting=false){
                const self = this;
                let item = new FileScanDocumentDto();
                let apiPromise = self.$api.FileScanApi.mergeFileScanDocuments(request);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        item = new FileScanDocumentDto(result);
                        if (replaceExisting) {
                            let itemIndex = _.findIndex(self.items, [self.itemKey, _.get(item, self.itemKey)]);
                            _.assign(self.items[itemIndex], item);
                        } else {
                            self.items.push(item);
                        }
                        self.$toast.success({ message: `${item.description} was created.` });
                    }).catch(e => {
                        self.$toast.error({ message: e.errorMessage });
                    })
                    .finally(() => {
                        self.refresh();
                        self.$nextTick().then(() => {
                            self.gridInstance.selectRows([item.fileScanDocumentID], false);
                        });
                    });
            },

            onAddDocuments(e) {
                if (!this.localSecurity.FileScanCanAttachFilesAndDocuments) return;
                this.showDocumentAdd();
            },

            onAddDocumentDragOver(e) {
                if (!this.localSecurity.FileScanCanAttachFilesAndDocuments || this.fileIsLocked) return;
                e.preventDefault();
                this.showDocumentAdd();
            },

            onChangeGridGroupBy(groupByCategory) {
                const self = this;
                let grid = _.get(this, "$refs.dataGrid.gridInstance", null);
                if (!grid) return;
                self.groupByCategory = groupByCategory;
                let columns = grid.option("columns") || [];
                _.each(columns, (column,i) => {
                    let dataField = groupByCategory ? 'fileScanCategoryID' : '';
                    if (_.hasIn(column, 'groupIndex') && column.dataField == dataField) {
                        grid.columnOption(i, 'groupIndex', 0);
                    } else if (_.hasIn(column, 'groupIndex')) {
                        grid.columnOption(i, 'groupIndex', -1);
                    }
                });
                if (self.isSecondaryFile) {
                    let primaryGrid = _.get(this, "$refs.primaryDataGrid.gridInstance", null);
                    if (!primaryGrid) return;
                    let primaryColumns = primaryGrid.option("columns") || [];
                    _.each(primaryColumns, (column,i) => {
                        let dataField = groupByCategory ? 'fileScanCategoryID' : '';
                        if (_.hasIn(column, 'groupIndex') && column.dataField == dataField) {
                            primaryGrid.columnOption(i, 'groupIndex', 0);
                        } else if (_.hasIn(column, 'groupIndex')) {
                            primaryGrid.columnOption(i, 'groupIndex', -1);
                        }
                    });
                }
            },

            onCopyMoveItem(e, type) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let keys = _.map(items, self.itemKey);
                self.$dialog.open({
                    title: `${type} Document${items.length > 1 ? 's': ''}`,
                    width: 300,
                    height: "auto",
                    resizable: false,
                    scrollable: false,
                    adaptive: true,
                    closeOnEsc: false,
                    component: FileNumberDialog,
                    props: {
                        allowLockedFiles: self.localSecurity.CanCopyDocumentsToLocked,
                        required: true
                    },
                    onOk(e) {
                        let resultItem = e.component.dialogResult();
                        let request = new FileScanDocumentCopyMoveRequestDto(
                            {
                                ordersID: resultItem.ordersID,
                                gfNo: resultItem.gfNo,
                                fileScanDocumentIDs: keys,
                                copy: _.isEqual(type, "Copy")
                            }
                        );
                        self.saveFileScanCopyMove(request);
                        return true;
                    }
                });
            },

            onReloadData(){
                this.fetchData();
            },

            onCopyMoveItemToCurrent(e, type) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let keys = _.map(items, self.itemKey);
                let request = new FileScanDocumentCopyMoveRequestDto(
                    {
                        ordersID: self.order.ordersID,
                        gfNo: self.order.gfNo,
                        fileScanDocumentIDs: keys,
                        copy: _.isEqual(type, "Copy")
                    }
                );
                self.saveFileScanCopyMove(request);
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.itemKey);

                    let apiPromise = self.$api.FileScanApi.deleteFileScanDocuments(toBeDeletedKeys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(keys => {
                            _.pullAllBy(self.items, items, self.itemKey);
                            let message = keys.length > 1 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                            if (error.axiosError.response && error.axiosError.response.status === 409) {
                                const errorMessage = error.axiosError.response.data;
                                self.$dialog.confirm(`Delete Error`, errorMessage);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onDocumentSearch: _.debounce(function (e) {
                this.tabIndex = this.searchAll ? 1 : 0;
                this.refresh();
                this.refreshSearch();
            }, 300, {trailing: true, leading: false}),

            onDocumentSearchClear() {
                this.searchText = "";
                this.refresh();
                this.refreshSearch();
            },

            onRefreshDocuments(e) {
                var self = this;

                self.fetchData();
                if (self.isSecondaryFile) {
                    self.fetchPrimaryData();
                }
                self.onDocumentSearchClear();
                self.selectedItems = [];
            },

            onDocumentSearchEnter(e) {
                if (_.hasIn(e, "value") && e.event.key == "Enter" && e.value.length > 0) {
                    this.tabIndex = this.searchAll ? 1 : 0;
                    this.refresh();
                    this.refreshSearch();
                }
            },

            onEditorPreparing(e){
                if(e.parentType !== "dataRow") return;
                if(_.indexOf(this.readOnlyColumns, e.dataField) >= 0) {e.editorOptions.readOnly = true; return;}
                e.editorOptions.readOnly = (this.fileIsLocked && !this.localSecurity.CanEditDocuments);
            },

            onEditDocument(e) {
                if(!e || !e.data) return;
                const self = this;
                let item = e.data;
                self.selectedItem = new FileScanDocumentDto(item);
                self.action = FileScanDocumentAction.Edit;
            },

            onEditProperties(e) {
                if(!e || !e.data) return;
                const self = this;
                let item = e.data;
                self.selectedItem = new FileScanDocumentDto(item);
                self.showFileScanProperties(new FileScanDocumentDto(item));
            },

            onExpandAllGroups(expand) {
                this.allGroupsExpanded = expand;
                this.allGroupsCollapsed = !expand;
                if (expand) {
                    this.gridInstance.expandAll();
                } else {
                    this.gridInstance.collapseAll();
                }
            },

            onExpandAllPrimaryGroups(expand) {
                this.allPrimaryGroupsExpanded = expand;
                this.allPrimaryGroupsCollapsed = !expand;
                if (expand) {
                    this.primaryGridInstance.expandAll();
                } else {
                    this.primaryGridInstance.collapseAll();
                }
            },

            onGotoFile(e) {
                if(!e || !e.data) return;
                this.$router.push(`/order/${e.data.ordersID}/document-mgmt`);
                this.searchAll = false;
                this.fetchData();
                this.onDocumentSearchClear();
            },

            onGridEditingStart(e) {
                if(!e || !e.data) return;
                this.saveAsFileType = 0;
                this.action = FileScanDocumentAction.Preview;
                this.selectedItems = [e.data];
            },

            onGridSelectionChanged(e) {
                this.canPrint = _.size(e.selectedRowsData) > 0 && !this.showPreview;
                this.saveAsFileType = 0;
                this.action = FileScanDocumentAction.Preview;
                this.selectedItems = e.selectedRowsData;
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, [self.itemKey, key]);
                if(itemIndex < 0) return;

                let updatedItem = new FileScanDocumentDto(_.assign({}, self.items[itemIndex], values));
                self.saveFileScanProperties(updatedItem)
                    .then(item => {
                        _.assign(self.items[itemIndex], new FileScanDocumentDto(item));
                        self.$toast.success({ message: `Document Properties for ${item.description} was saved.` });
                        return true;
                    }).catch(e => {
                        self.validationErrors = [e.errorMessage];
                        self.$toast.error({ message: `Error Saving Document Properties for ${updatedItem.description}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });

            },

            onMergeItems(e) {
                if(!e || !e.data) return;
                const self = this;
                let docs = e.data;
                if (docs.length <= 1) {
                    self.$toast.info(`Must have more than 1 document selected in order to merge.`);
                }
                let items = [];
                for(let x = 0; x < docs.length; x++)
                {
                    items.push({id: docs[x].fileScanDocumentID, description: docs[x].description, ordinal: x});
                }
                self.$dialog.open({
                    title: `Merge Documents`,
                    height:"auto",
                    minHeight: 450,
                    width: 600,
                    addaptive: true,
                    component: SortableList,
                    props: { orderId: 0, items },
                    onOk(e) {
                        let fileScanDocumentIDs = _.map(e.component.listItems, "id");
                        if(_.isEmpty(fileScanDocumentIDs)) return true;
                        self.showCombinedFileScanProperties(fileScanDocumentIDs);
                    }
                });
            },

            onPdfDocumentLoaded() {
                this.canPrint = true;
            },

            onPdfDocumentPrinted() {
                this.$rqBusy.endWait(true);
            },

            onPrintItems(e) {
                if(!e || !e.data) return;
                if (!this.canPrint) {this.$toast.error({ message: 'Document is still loading.' });return; }
                this.action = FileScanDocumentAction.Print;
                if (!this.showPreview) this.$rqBusy.startWait(true);
            },

            onReset(e) {
                this.fetchData();
            },

            onRowCollapsedOrExpanded(e) {
                let rowKey = e.key[0];
                let groupRows = _.clone(_.filter(e.component.getVisibleRows(), ['rowType', 'group']));
                let currentRow = _.find(groupRows, ['data.key', rowKey]);
                currentRow.isExpanded = e.expanded;
                this.allGroupsExpanded = _.every(groupRows, ['isExpanded', true]);
                this.allGroupsCollapsed = _.every(groupRows, ['isExpanded', false]);
            },

            onPrimaryRowCollapsedOrExpanded(e) {
                let rowKey = e.key[0];
                let groupRows = _.clone(_.filter(e.component.getVisibleRows(), ['rowType', 'group']));
                let currentRow = _.find(groupRows, ['data.key', rowKey]);
                currentRow.isExpanded = e.expanded;
                this.allPrimaryGroupsExpanded = _.every(groupRows, ['isExpanded', true]);
                this.allPrimaryGroupsCollapsed = _.every(groupRows, ['isExpanded', false]);
            },

            onSaveItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let onCancel = function (args) {
                    self.action = FileScanDocumentAction.Download;
                }
                let onOk = function (args) {
                    self.action = FileScanDocumentAction.DownloadZip;
                }
                if (items.length > 1) {
                    self.$dialog.confirm("Confirm Zip", `Would you like to zip the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName} into one file?`, onOk, onCancel, { cancelTitle: 'No', okTitle: 'Yes'});
                } else {
                    self.action = FileScanDocumentAction.Download;
                }
            },

            onSaveItemAs(e) {
                if(!e || !e.data) return;
                this.showSaveDocumentsAs(e.data);
            },

            onSearchGridFocusedRowChanged(e) {
                if(!e || !e.row.data) return;
                this.saveAsFileType = 0;
                this.action = FileScanDocumentAction.Preview;
                this.selectedItems = [e.row.data];
            },

            onSetCategory(e) {
                if(!e || !e.data) return;
                const self = this;
                let ids = _.map(e.data, self.itemKey) || [];
                self.showAssignCategory(ids);
            },

            onSetTags(e, assignType) {
                if(!e || !e.data) return;
                const self = this;
                let fileScanDocumentIDs = [];
                let tagIDs = [];
                if (assignType === 'set') {
                    fileScanDocumentIDs = [_.get(e.data, self.itemKey)];
                    tagIDs = e.data.tagIDs;
                } else {
                    fileScanDocumentIDs = _.map(e.data, self.itemKey);
                }
                let request = new FileScanDocumentAssignTagRequestDto({fileScanDocumentIDs, tagIDs, assignType});

                self.showAssignTags(request);
            },

            onShowPreviewChange(e) {
                this.gridInstance.refresh();
            },

            onTagFilterChange(e) {
                this.refresh();
                this.refreshSearch();
            },

            onUpdateDocument(item) {
                const self = this;
                let itemIndex = _.findIndex(self.items, [self.itemKey, _.get(item, self.itemKey)]);
                _.assign(self.items[itemIndex], item);
                self.gridInstance.refresh();
            },

            onEmailItems(e){
                const self = this;
                let ids = _.map(e.data, self.itemKey);
                let apiPromise = self.$api.FileScanApi.getFileScanDocumentData(FileScanDocumentAction.Download, 0, ids);
                self.$rqBusy.wait(apiPromise)
                    .then(files => {
                        let props = {
                            attachments: _.map(files, f => {
                                return { name: f.commonFileName, type: "BASE64", contentBase64: f.content };
                            })
                        };
                        self.$dialog.open({
                            title: "Email Documents",
                            width: "90%",
                            height: "90%",
                            okTitle: "Send",
                            component: EmailModal,
                            props,
                            onOk(e) { return e.component.onOk(); }
                        });
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error Downloading Documents.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onWindowResize: _.debounce(function(e) {
                this.gridInstance.updateDimensions();
                let searchGrid = this.getSearchGrid();
                if (!_.isEmpty(searchGrid)) {
                    searchGrid.updateDimensions();
                }
                let primaryGrid = this.getPrimaryGrid();
                if (!_.isEmpty(primaryGrid)) {
                    primaryGrid.updateDimensions();
                }
            }, 500),

            refresh() {
                this.clear();
                this.selectionActions = this.getGridSelectionActions();
                let grid = _.get(this.$refs, "dataGrid.gridInstance", {});
                if (!_.isEmpty(grid)) {
                    grid.refresh();
                    grid.searchByText(this.searchText);
                    grid.updateDimensions();
                }
                let primaryGrid = _.get(this.$refs, "primaryDataGrid.gridInstance", {});
                if (!_.isEmpty(primaryGrid)) {
                    primaryGrid.refresh();
                    primaryGrid.searchByText(this.searchText);
                    primaryGrid.updateDimensions();
                }
            },

            refreshSearch() {
                let searchGrid = this.getSearchGrid();
                if (this.searchAll && !_.isEmpty(searchGrid)) {
                    searchGrid.refresh();
                    searchGrid.searchByText(this.searchText);
                }
                let primaryGrid = this.getPrimaryGrid();
                if (this.searchAll && !_.isEmpty(primaryGrid)) {
                    primaryGrid.refresh();
                    primaryGrid.searchByText(this.searchText);
                }
            },

            saveFileScanCategory(fileScanCategoryID, ids){
                const self = this;
                let apiPromise = self.$api.FileScanApi.saveFileScanCategory(fileScanCategoryID, ids);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let newItems = _.map(result, i => new FileScanDocumentCategoryResultDto(i));
                        _.each(newItems, i => {
                            let itemIndex = _.findIndex(self.items, [self.itemKey, _.get(i, self.itemKey)]);
                            _.assign(self.items[itemIndex], i);
                        });
                        let category = newItems[0].fileScanCategoryDisplay;
                        let noun = (newItems.fileCount == 0) ? self.itemTypeName : self.itemTypeNamePlural;
                        self.$toast.success({ message: `${newItems.length} ${noun} were set to ${category}.` });
                    }).catch(e => {
                        self.$toast.error({ message: e.errorMessage });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            saveFileScanAssignTags(request){
                const self = this;
                let apiPromise = self.$api.FileScanApi.saveFileScanAssignTags(request);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let updatedItems = _.map(result, i => new FileScanDocumentAssignTagResultDto(i));
                        _.each(updatedItems, f => {
                            let itemIndex = _.findIndex(self.items, [self.itemKey, _.get(f, self.itemKey)]);
                            let item = new FileScanDocumentDto(_.assign({}, self.items[itemIndex], f));
                            _.assign(self.items[itemIndex], item);
                        });
                        let verb = request.assignType == 'set' ? "Assigned" : (request.assignType == 'add' ? "Added" : "Removed");
                        let noun = (updatedItems.length == 0) ? self.itemTypeName : self.itemTypeNamePlural;
                        self.$toast.success({ message: `${updatedItems.length} ${noun} had ${request.tagIDs.length} tag${request.tagIDs.length == 0 ? '' : 's'} ${verb}.` });
                    }).catch(e => {
                        self.$toast.error({ message: e.errorMessage });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            saveFileScanCopyMove(request){
                const self = this;
                let apiPromise = self.$api.FileScanApi.saveFileScanCopyMove(request);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let copyMoveResult = new FileScanDocumentCopyMoveResultDto(result);
                        let verb = copyMoveResult.copy ? "Copied" : "Moved";
                        let noun = (copyMoveResult.fileCount == 0) ? self.itemTypeName : self.itemTypeNamePlural;
                        self.$toast.success({ message: `${copyMoveResult.fileCount} ${noun} were ${verb} to ${copyMoveResult.gfNo}.` });
                        self.fetchData();
                    }).catch(e => {
                        self.$toast.error({ message: e.errorMessage || "Error trying to copy or move files(s)." });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            saveFileScanProperties(item){
                const self = this;
                let itemIndex = _.findIndex(self.items, [self.itemKey, _.parseNumber(_.get(item, self.itemKey), -1)]);
                let original = new FileScanDocumentDto(_.cloneDeep(self.items[itemIndex]));
                if (!_.isEqual(item.description, item.standardDescription) && !item.useDefaultDescription) {
                    item.standardDescription = item.description;
                }
                let changes = self.getAuditChanges(original.toDataObject(), item.toDataObject(), ['content', 'contentType', 'tagNames', 'fileSize']);
                if (changes.length == 0) {
                    self.$toast.info({ message: "No changes detected" });
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.FileScanApi.saveFileScanProperties(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise);
            },

            saveDefaultCategory(defaultCategory){
                const self = this;
                let original = _.clone(self.user);
                let newUser = _.clone(self.user);
                newUser.defaultCategory = defaultCategory;
                let changes = self.getAuditChanges(original, newUser);
                if (changes.length == 0) {
                    self.$toast.info({ message: "No changes detected" });
                    return;
                }
                let storePromise = self.$store.dispatch(UPDATE_SESSION_USER, {user: newUser, changes: changes});
                self.$rqBusy.wait(storePromise)
                    .then(user => {
                        let fileScanCategory = _.find(self.fileScanCategories, ['id', user.defaultCategory]) || {};
                        let fileScanCategoryName = '(Blank)';
                        if (!_.isEmpty(fileScanCategory)) fileScanCategoryName = fileScanCategory.name;
                        self.$toast.success({ message: `User Default Category set to ${fileScanCategoryName}.` });
                    }).catch(e => {
                        console.log(e.message);
                        self.$toast.error({ message: `Error Setting Default Category.` });
                    });
            },

            showAssignCategory(ids){
                const self = this;

                let onOk = (e) => {
                    let fileScanCategoryID = _.parseNumber(e.selectedValue, 0);
                    self.saveFileScanCategory(fileScanCategoryID, ids);
                };
                let onCancel = e => {
                    self.refresh();
                };

                self.$dialog.promptSelect({
                    title: "Assign Category",
                    label: "Category",
                    valueExpr: "id",
                    displayExpr: "name",
                    okTitle: "Save",
                    items: self.fileScanCategories,
                    requiredMessage: "Selection required",
                    onOk: onOk,
                    onCancel: onCancel
                });
            },

            showAssignTags(request){
                const self = this;
                let verb = request.assignType == 'set' ? "Assign" : (request.assignType == 'add' ? "Add" : "Remove");

                let onOk = (e) => {
                    request.tagIDs = e.component.tagIDs.length > 0 ? e.component.tagIDs : [];
                    self.saveFileScanAssignTags(request);
                };
                let onCancel = e => {
                    self.refresh();
                };

                self.$dialog.open({
                    title: `${verb} Tag(s)`,
                    height: "250",
                    width: "500",
                    adaptive: true,
                    props: {
                        originalTagIDs: request.tagIDs
                    },
                    component: FileScanDocumentAssignTag,
                    closeOnEsc: true,
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", closeOnComplete: true, onClick: onCancel },
                        { title: "Save", automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", closeOnComplete: true, onClick: onOk }
                    ]

                });
            },

            showDocumentAdd() {
                const self = this;
                let onCancel = (e) => {
                    self.clear();
                }
                let onOk = (e) => {
                    let form = e.component;
                    form.saveDocuments()
                        .then(result => {
                            result.rejectedScannedDocuments.forEach(doc => {
                                self.$toast.error(`${doc.responseMessage}`)
                            })
                            self.fetchData();
                        }).catch(e => {
                            console.log(e.errorMessage);
                            self.$toast.error({ message: e.errorMessage });
                            return false;
                        })
                        .finally(() => {
                            self.refresh();
                            return true;
                        });
                };
                let existingItems = [];
                _.each(self.items, i => {
                    let existingItem = _.pick(i, ["fileType", "fileScanCategoryID", "description"]);
                    existingItems.push(existingItem);
                });
                self.$dialog.open({
                    title: `Add Document(s): ${self.order.gfNo}`,
                    props: {existingItems: existingItems},
                    maxHeight: window.innerHeight > 768 ? "90%" : 700,
                    width: window.innerWidth > 1367 ? "90%" : 1200,
                    minHeight: 500,
                    minWidth: 1100,
                    // resizable: false,
                    // scrollable: false,
                    adaptive: true,
                    closeOnEsc: false,
                    component: FileScanDocumentsAdd,
                    onOk: onOk,
                    onCancel: onCancel,
                });

            },

            showCombinedFileScanProperties(fileScanDocumentIDs) {
                const self = this;
                let fileType = DocumentFileType.PDF_COMBINED;

                let item = new FileScanDocumentDto(
                    {
                        ordersID: self.orderId,
                        contentType: DocumentFileType.contentType(fileType),
                        description: `Combined_Document_${DateTimeHelper.now("yyyy-MM-dd hh:mm:ss a")}`,
                        numberOfPages: 1,
                        documentType: FileScanDocumentType.Attached,
                        fileScanDescriptionID: 0,
                        fileTypeDisplay: DocumentFileType.displayValue(fileType),
                        fileType: fileType,
                        fileScanCategoryID: self.user.defaultCategory,
                        requiresAttention: false,
                        fileScanDocumentDuplicateAction: 0,
                        dateModified: DateTimeHelper.nowTenant(),
                        useDefaultDescription: self.localSecurity.PredefinedDescriptions,
                        tagIDs: []
                    });

                let onCancel = (e) => {
                    self.refresh();
                }
                let onOk = (e) => {
                    let form = e.component;
                    let item = form.item;
                    if (item.useDefaultDescription) {
                        item.description = self.lookupHelpers.getLookupItemName(self.lookupItems.FILE_SCAN_DESCRIPTIONS, item.fileScanDescriptionID);
                    }
                    self.validationErrors = [];
                    if (form.requiresAttention && item.fileScanDocumentDuplicateAction <= 0) {
                        form.errorMessage = `Please select an action.`;
                        form.disable();
                        return false;
                    }
                    if (!self.isUniqueItem(item) && item.fileScanDocumentDuplicateAction != FileScanDocumentDuplicateAction.ReplaceExisting) {
                        form.errorMessage = `Description [${item.description}] is already in use, please select an action. `;
                        form.requiresAttention = true;
                        form.disable();
                        return false;
                    }
                    if (!item.isValid) {
                        form.errorMessage = item.validationErrors[0];
                        form.hasErrors = form.hasErrors || _.parseBool(!_.isEmpty(form.errorMessage));
                        return false;
                    }
                    item.commonFileName = `${item.description}${DocumentFileType.fileExtension(fileType)}`;
                    let request = new FileScanDocumentMergeRequestDto({fileScanDocumentIDs, fileScanDocument: item});
                    let replaceExisting = item.fileScanDocumentDuplicateAction == FileScanDocumentDuplicateAction.ReplaceExisting;
                    self.mergeFileScanDocuments(request, replaceExisting);
                    return true;
                };
                self.$dialog.open({
                    title: "Combined Document Properties",
                    width: "650",
                    height: "auto",
                    adaptive: true,
                    closeOnEsc: true,
                    component: FileScanDocumentProperties,
                    props: {
                        item: item
                    },
                    onOk: onOk,
                    onCancel: onCancel
                });

            },

            showFileScanProperties(original) {
                const self = this;
                original.useDefaultDescription = self.localSecurity.PredefinedDescriptions ? true : original.useDefaultDescription;

                let onCancel = (e) => {
                    self.clear();
                }
                let onOk = (e) => {
                    let form = e.component;
                    let item = form.item;
                    self.validationErrors = [];
                    if (!item.isValid) {
                        form.errorMessage = item.validationErrors[0];
                        form.hasErrors = true;
                        return false;
                    }
                    if (!self.isUniqueItem(item) && item.fileScanDocumentDuplicateAction == 0) {
                        item.fileScanCategoryID = self.getExistingFileScanCategoryID({description: item.description, fileType: item.fileType});
                        item.fileScanDocumentDuplicateAction = FileScanDocumentDuplicateAction.ReplaceExisting;
                        item.requiresAttention = true;
                        form.errorMessage = `Document with file type [${item.fileTypeDisplay}] and Description [${item.description}] already exists.  Please change the description or choose the appropriate Action.`;
                        form.hasErrors = true;
                        form.originalDescription = item.description;
                        return false;
                    }
                    self.saveFileScanProperties(item)
                        .then(item => {
                            let itemIndex = _.findIndex(self.items, [self.itemKey, _.get(item, self.itemKey, 0)]);
                            _.assign(self.items[itemIndex], item);
                            self.$toast.success({ message: `Document Properties for ${item.description} was saved.` });
                            self.refresh();
                            return true;
                        }).catch(e => {
                            form.errorMessage = e.errorMessage;
                            form.hasErrors = true;
                            return false;
                        });
                };
                self.$dialog.open({
                    title: "Edit Document Properties",
                    height: "auto",
                    width: "650",
                    adaptive: true,
                    closeOnEsc: true,
                    component: FileScanDocumentProperties,
                    props: {
                        item: _.cloneDeep(original)
                    },
                    onCancel: onCancel,
                    onOk: onOk,
                    okTitle: "Save"
                });

            },

            showSaveDocumentsAs(items) {
                const self = this;
                let saveAsItems = _.sortBy(_.filter(self.documentFileTypes, ft => _.includes(self.saveAsAllowed, ft.id)), ['name']);

                let onCancel = (e) => {
                    self.clear();
                }
                let onOk = (e) => {
                    e.component.errorMessage = "";
                    e.component.v$.$touch();
                    if (e.component.v$.$error) {
                        e.component.errorMessage = 'Please correct the highlighted fields.';
                        return false;
                    }
                    let fileType = _.parseNumber(e.component.fileType, 0);
                    if (fileType == 0) {
                        return false;
                    }
                    let saveAsZip = _.parseBool(e.component.saveAsZip, false);
                    self.saveAsFileType = fileType;
                    self.action = saveAsZip ? FileScanDocumentAction.DownloadZip : FileScanDocumentAction.Download;
                    self.$nextTick().then(() => {
                        self.clear();
                    });
                    return true;
                };
                let noun = items.length > 1 ? 'Documents' : 'Document';
                self.$dialog.open({
                    title: `Save ${noun}`,
                    width: "400",
                    height: "auto",
                    resizable: false,
                    scrollable: false,
                    adaptive: true,
                    closeOnEsc: true,
                    component: DocumentsSaveAsDialog,
                    props: { saveAsItems: saveAsItems, showZip: items.length > 1 },
                    onCancel: onCancel,
                    onOk: onOk,
                });

            },
        }
    }
</script>
